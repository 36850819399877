//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SDialogBox',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    buttons: {
      type: Object,
      default: () => ({}),
    },
    noDismiss: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      privateShow: false,
      ready: false,
      displayDialog: true,
    };
  },
  computed: {
    ...mapGetters({
      getToolbarSize: 'screen/getToolbarSize',
    }),
    optionsDialog() {
      const fullscreen = !!this.options.fullscreen;
      const maxWidth = this.options.maxWidth || '80%';
      const persistent = !!this.options.persistent;
      const scrollable = !!this.options.scrollable;
      const width = this.options.width || 1024;
      const contentClass = this.options.contentClass;
      const retainFocus = this.options.retainFocus !== false;
      return {
        fullscreen,
        maxWidth,
        persistent,
        scrollable,
        width,
        contentClass,
        retainFocus,
      };
    },
    hasActionButtons() {
      return (this.buttons.closeText || this.buttons.confirmText) && !this.optionsDialog.fullscreen;
    },
    hasToolbarButton() {
      return this.buttons.confirmText && this.optionsDialog.fullscreen;
    },
    hasToolbarMenuSlot() {
      return !!this.$slots.dialogToolbarMenu;
    },
    fullscreenOption() {
      return this.optionsDialog.fullscreen;
    },
  },
  watch: {
    privateShow(value) {
      if (!value) {
        this.$emit('update:show', false);
        this.$emit('dismiss');
        if (this.options.fullscreen) {
          this.enableSideMenu();
        }
      } else if (this.options.fullscreen) {
        setTimeout(() => {
          this.disableSideMenu();
        }, 250);
      }
    },
    fullscreenOption() {
      this.displayDialog = false;
      this.$nextTick(() => {
        this.displayDialog = true;
      });
    },
  },
  created() {
    this.privateShow = this.show;
    this.addCloseDialogFn(this.close);
  },
  destroyed() {
    if (this.options.fullscreen) {
      this.enableSideMenu();
    }
    this.removeLastCloseDialogFn();
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    ...mapActions({
      enableSideMenu: 'menu/enableSideMenu',
      disableSideMenu: 'menu/disableSideMenu',
      addCloseDialogFn: 'dialogs/addCloseFn',
      removeLastCloseDialogFn: 'dialogs/removeLastCloseFn',
    }),
    close() {
      this.privateShow = false;
      this.$emit('close');
    },
    confirm() {
      if (!this.noDismiss) {
        this.privateShow = false;
      }
      this.$emit('confirm');
    },
  },
};
